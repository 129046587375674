@charset "utf-8";


// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"style",
	"custom",
	"override"
;

$color-green: #093;
$color-lightgreen: #82C300;
$color-darkgreen: #336633;
$color-blue: #00A4E1;

/* ================================================
// Style
================================================ */

.section {
	position: relative;
	min-height: 50px;
	padding: 30px 0;
}

.bg-green {
  background: $color-green;
}

.navbar-brand {
	margin-left: auto;
	margin-right: auto;
	border-right: none;
	min-width: 150px;
	padding: 18px 0px 18px;
}

.navbar-brand .logo {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  display: block;
  height: 30px;
  background-image: url("/assets/front-end/img/logo-fe.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
	background-color: #FFF;
	border-bottom: 2px solid $color-blue;
}

#content{
  margin-top: 10px;
}

#sub-title
{
	margin-top:30px;
}
#sub-title > h2
{
	color: $color-blue;
}
#sub-title > h2
{
	color: $color-blue;
}
#sub-title > hr
{
	margin-top:0;
    padding: 0; 
    /*border-color: $color-blue;*/
}

#awards {
	padding-top: 0;
	overflow: hidden;
}
#awards .list-inline > li {
	padding: 5px 30px;
}
#awards .list-inline > li img {
    opacity: 0.6;
}
#awards .list-inline > li img:hover {
    opacity: 1.0;
}

/* ----------------------------------------------------------------------------
    Testimonials slider
---------------------------------------------------------------------------- */

.flexslider {
	margin-bottom: 30px;
}

.flexslider ul,
.flexslider ol {
	padding: 0;
	list-style: none;
}

.flex-viewport > ul > li {
	padding-bottom: 15px;
}

.flex-manual {
	margin-top: 15px;
	border-bottom: 1px solid #e9e9e9;
	height: 120px;
}

.flex-manual .wrap {
	padding: 0;
}

.flex-manual .switch {
	-webkit-transition: border 0.3s ease, opacity 0.3s ease;
	-moz-transition: border 0.3s ease, opacity 0.3s ease;
	-o-transition: border 0.3s ease, opacity 0.3s ease;
	transition: border 0.3s ease, opacity 0.3s ease;
	margin: 0 0 0 30px;
	height: 120px;
	padding: 15px 0;
	text-align: left;
	opacity: 0.75;
	cursor: pointer;
}

.flex-manual .switch * {
	-webkit-transition: color 0.3s ease;
	-moz-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.flex-manual .switch p {
	padding: 12px 0 0 80px;
	font-size: 16px;
	color: #dadada;
}

.flex-manual .switch:not(:hover):not(.flex-active) .highlight {
	color: #dadada;
}

.flex-manual .switch.flex-active {
	border-style: solid;
	border-width: 0 0 1px;
}

.flex-manual .switch.flex-active,
.flex-manual .switch:hover {
	opacity: 1;
}

.flex-manual .switch.flex-active p,
.flex-manual .switch:hover p {
	color: #606060;
}

.feedback-section {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	overflow: hidden;
	padding: 20px 0 0;
}

.feedback-section .section-header {
	margin-bottom: 30px;
}

.feedback-controls-section {
	padding: 10px 0 0;
	background-color: #ffffff;
}

/* ----------------------------------------------------------------------------
    Pricing
---------------------------------------------------------------------------- */

.flat .plan {
    border-radius: 6px;
    list-style: none;
    padding: 0 0 20px;
    margin: 0 0 15px;
    background: #fff;
    text-align: center;
}
.flat .plan li {
    padding: 10px 15px;
    border-top: 1px solid #f5f5f5;
    -webkit-transition: 300ms;
    transition: 300ms;
    color: #000;
}
.flat .plan li.plan-price {
    border-top: 0;
	font-size: 15px;
	font-weight: 300;
	line-height: 25px;
}
.flat .plan li.plan-price > strong {
	font-size: 50px;
	font-weight: 600;
	line-height: 25px;
}
.flat .plan li.plan-name {
    border-radius: 6px 6px 0 0;
    padding: 15px;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
    background: $color-blue;
    margin-bottom: 30px;
    border-top: 0;
}
.flat .plan li > strong {
    color: $color-blue;
}
.flat .plan li.plan-action {
    margin-top: 10px;
    border-top: 0;
}
.flat .plan li.plan-action > a {
    background-color: $color-green;
    color: #fff;
    border-color: $color-lightgreen;
}
.flat .plan.featured {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
.flat .plan.featured li.plan-name {
    background: #009933;
}
.flat .plan:hover li.plan-name {
    background: #363;
}

/* ----------------------------------------------------------------------------
    Footer
---------------------------------------------------------------------------- */

#footer {
    margin-top: 0px;
    padding: 20px 30px 10px;
	font-weight: 500;
    background-color: #353A3D;
	background-repeat: no-repeat;
	background-position: top center;
	background-attachment: fixed;
}

#footer p {
	font-size: 15px;
	line-height: 1.6;
}

#footer a {
	color: #ffffff;
}

#footer a:hover {
	padding-bottom: 2px;
	text-decoration: underline;
}


#footer .footer-content {
	padding: 15px 0 5px;
	color: #ffffff;
}

#footer .heading {
	margin-bottom: 25px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.36);
}

#footer .list-inline {
	text-align: center;
	margin: 0 auto;
}

#footer .list-inline li {
	padding: 0 15px 15px;
}

#footer .list-inline li a:hover {
	text-decoration: none;
	opacity: 0.7;
}

#footer .list-inline li .icon {
	font-size: 50px;
}

#footer .list-unstyled li {
	position: relative;
	margin-bottom: 5px;
	padding-left: 30px;
	font-size: 15px;
	line-height: 1.6;
}

#footer .list-unstyled li .icon {
	position: absolute;
	left: 0;
	font-size: 20px;
}


.footer-copyright {
	padding: 15px 30px;
	font-size: 14px;
	font-weight: 500;
}

/* ----------------------------------------------------------------------------
    Back to top button
---------------------------------------------------------------------------- */

.back-to-top {
	-webkit-transition: opacity 0.5s ease;
	-moz-transition: opacity 0.5s ease;
	-o-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
	position: fixed;
	right: 20px;
	bottom: 20px;
	display: none;
	width: 40px;
	height: 40px;
	font-size: 12px;
	text-align: center;
	line-height: 40px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.8);
	cursor: pointer;
	z-index: 9999;
	opacity: 0.4;
}

.back-to-top:hover {
	opacity: 1;
}


/* ----------------------------------------------------------------------------
    Blog
---------------------------------------------------------------------------- */

.post-tag {
	display: inline-block;
	margin: 0.168em;
	margin-left: 0;
	text-decoration: none;
	text-transform: lowercase;
	color: #3e6d8e;
	background-color: #dfeaf1;
	border: 0 solid #dfeaf1;
	white-space: nowrap;
	transition: all 0.1s ease-in;
}

a[href] {
	.post-tag {
		&:hover {
			background-color: #c3dae9;
			border-color: #c3dae9;
			color: #365f7c;
			text-decoration: none;
		}
	}
}


//

.blog {
	padding: 50px 0 100px;
}

.post {
	position: relative;
	margin-bottom: 70px;
	padding-left: 1px;
}

.post .post-content {
	zoom: 1;
}

.post .post-content:before,
.post .post-content:after {
	content: "";
	display: table;
}

.post .post-content:after {
	clear: both;
}

.post .post-content .post-image {
	margin-bottom: 20px;
}

.post-date {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 67px;
	height: 67px;
	padding: 8px 0 0;
	font-size: 0;
	text-align: center;
	color: #ffffff;
}

.post-date span {
	display: inline-block;
	width: 100%;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1;
}

.post-date .post-day {
	margin-bottom: 2px;
	font-size: 34px;
	line-height: 33px;
}

.post-date .post-year {
	padding-right: 0;
	position: absolute;
	top: 110%;
	left: 0;
	right: 0;
	font-weight: 300;
	color: #8d8d8d;
}

.post-thumbnail {
	margin-bottom: 30px;
	overflow: hidden;
}

.post-thumbnail a {
	position: relative;
	display: block;
	overflow: hidden;
}

.post-thumbnail a:after {
	-webkit-transition: opacity 0.3s ease;
	-moz-transition: opacity 0.3s ease;
	-o-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: block;
	content: "";
	opacity: 0;
}

.post-thumbnail a:hover:after {
	opacity: 0.2;
}

.post-thumbnail img {
	-webkit-transition: -webkit-transform 2s ease;
	-moz-transition: -moz-transform 2s ease;
	-o-transition: -o-transform 2s ease;
	transition: -webkit-transform 2s ease,-moz-transform 2s ease,-o-transform 2s ease,transform 2s ease;
}

.post-thumbnail:hover img {
	-webkit-transform: scale(1.2) rotate(4deg);
	-moz-transform: scale(1.2) rotate(4deg);
	-o-transform: scale(1.2) rotate(4deg);
	-ms-transform: scale(1.2) rotate(4deg);
	transform: scale(1.2) rotate(4deg);
}

.post-title {
	margin: -8px 0 11px;
	line-height: 1.2;
}

.post-title a:hover {
	opacity: 0.7;
}

.post-meta {
	margin: 0 0 12px;
}

.post-meta span {
	vertical-align: top;
	display: inline;
	padding-right: 4px;
	color: #8d8d8d;
}

.post-meta span > a {
	color: inherit;
}

.post-meta span > i {
	padding-right: 7px;
}

.post-meta .post-comments {
	padding-left: 7px;
}

.post-text-content[class*="col-"] .post-meta .post-comments {
	padding-left: 0;
}

.post-preview .more-link {
	margin: 18px 0 0;
	padding-left: 52px;
	padding-right: 52px;
}

.post-tags-box {
	position: relative;
	margin: 25px 0 10px 0;
	display: inline-block;
	height: 35px;
	line-height: 35px;
}

.post-tags-box i {
	vertical-align: top;
	display: inline-block;
	line-height: 27px;
}

.post-tags-box .tags-links {
	vertical-align: top;
	display: inline-block;
	padding-left: 15px;
	line-height: 20px;
}

.post-tags-box a {
	margin-right: 15px;
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
}

.blog .sidebar {
	padding: 0 15px 5px 5px;
}

.blog-widget {
	margin-bottom: 25px;
}

.blog-widget > h6 {
	margin-top: 0;
	padding: 20px 0 15px;
	font-weight: 500;
	text-transform: uppercase;
	border-bottom: 1px solid #e9e9e9;
}

.blog-search .search-form {
	position: relative;
}

.blog-search button {
	position: absolute;
	right: 3%;
	top: 50%;
	margin-top: -11px;
	padding: 6px;
	font-size: 14px;
	line-height: 1;
	border: none;
	background-color: transparent;
	outline: none;
}

.blog-search button .fa {
	-webkit-transition: color 0.2s ease;
	-moz-transition: color 0.2s ease;
	-o-transition: color 0.2s ease;
	transition: color 0.2s ease;
}

.blog-search button:not(:hover):not(:active) .fa {
	color: #606060;
}

.blog-categories .fa {
	margin-right: 15px;
	color: #000000;
}

.blog-categories > ul > li {
	padding: 0 0 10px;
}

.blog-categories a:not(:active):not(:hover) {
	color: #8d8d8d;
}

.blog-recent-posts .recent-posts-item {
	zoom: 1;
	margin-bottom: 13px;
}

.blog-recent-posts .recent-posts-item:before,
.blog-recent-posts .recent-posts-item:after {
	content: "";
	display: table;
}

.blog-recent-posts .recent-posts-item:after {
	clear: both;
}

.blog-recent-posts .recent-posts-item-image {
	float: left;
	display: block;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	overflow: hidden;
}

.blog-recent-posts .recent-posts-item-image img {
	width: 100%;
}

.blog-recent-posts .recent-posts-item-info {
	float: left;
	width: 240px;
	padding: 6px 0 0 10px;
}

.blog-recent-posts .recent-posts-item-title,
.blog-recent-posts .recent-posts-item-date {
	display: inline-block;
	width: 100%;
	line-height: 20px;
}

.blog-recent-posts .recent-posts-item-title {
	font-weight: 700;
}

.blog-recent-posts .recent-posts-item-date {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 300;
	color: #8d8d8d;
}

.recent-comments-item .fa {
	margin-right: 5px;
}

.recent-comments-item {
	margin-bottom: 15px;
}

.recent-comments-item-author {
	color: #8d8d8d;
}

.social-networks-item {
	margin: 15px 10px 0;
}

.social-networks-item .icon {
	font-size: 30px;
}

.blog-tags .tags-list {
	font-size: 0;
}

.blog-tags .tags-item {
	margin: 0 5px 5px 0;
	display: inline-block;
}

.blog-tags .tags-item a {
	-webkit-transition: background 0.2s ease;
	-moz-transition: background 0.2s ease;
	-o-transition: background 0.2s ease;
	transition: background 0.2s ease;
	display: inline-block;
	height: 22px;
	padding: 0 10px;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	text-decoration: none;
	color: #ffffff;
	line-height: 22px;
	vertical-align: top;
	background: rgba(0, 0, 0, 0.2);
}

.step .round-circle {
  position: absolute;
  height: 30px;
  width: 30px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: $text-color-highlight;
  float: left;
  color: #fff;
  margin-top: 5px;
  margin-left:5px;
  top:50%;
  font-size: 20px;
}
.step .content {
	position: relative;
	margin-left: 80px;
}


/*  */
nav.navbar {
	min-height: 50px;
	margin-bottom: 1px;
	border: 1px solid transparent;
   -webkit-transition: all 0.4s ease;
   transition: all 0.4s ease;
}
.navbar-nav {
	margin-top: 1.6%;
	margin-bottom: 10px;
}
.navbar-header > p {  
	padding-top: 17px;
}

nav.navbar.shrink {
  min-height: 25px !important;
}
nav.shrink a {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 14px;
}
nav.navbar.shrink .navbar-header > p {  
	padding-top: 10px;
}
nav.navbar.shrink .navbar-header .navbar-brand .logo {
  padding-top: 5px;
}

nav.shrink .navbar-brand {
  font-size: 5px;
}
nav.shrink .navbar-toggle {
  padding: 4px 5px;
  margin: 8px 15px 8px 0;
}

.navbar-header .navbar-tag {
	padding: 0;
	margin:  -8px 0 0 10px;
	font-size: 12px;
}

.navbar-header a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); 
}

.navbar-nav > li > a:hover {
	/*border-bottom: 2px solid #00A4E1;*/
	 box-shadow: 0 -1px 0 $color-blue; 
}
