.landing .navbar-brand{border: none;padding-left: 0}
.landing .navbar{padding: 5px 0}
.landing .navbar.affix{left: 0;right: 0;padding: 0;  
  -webkit-transition: padding ease-in-out 0.3s;
    transition: padding ease-in-out 0.3s;
 }
.landing .navbar-nav > .active > a, 
.landing .navbar-nav > .active > a:hover, 
.landing .navbar-nav > .active > a:focus{
  background-color: transparent;
  /*color: #22baa0;*/
    color: #00A4E1;
}
.landing .btn-circle{cursor: inherit;}
.landing img{max-width: 100%;}
/*.main-visual{background: url(../images/render-1.jpg) #233445 center top no-repeat;background-size: cover;} */
.main-visual .bg-inverse{background-color: rgba(35,52,69,0.96);}
.main-visual h1{font-weight: 300;margin: 40px 0;line-height: 1.3}
.main-visual .container{padding:110px 0}
.font-thin{font-weight: 300}
.m-large{margin-top: 15px; margin-bottom: 15px;}

.landing .gallery{text-align: center;}
.landing .gallery .thumbnail{
  display: inline-block;
  border-radius: 0;
  padding: 10px;
  margin: 20px 0;
}


/*phone*/
@media (max-width: 767px) {
  .landing #header, .landing  #header .container{padding: 0}
  .landing .container{padding: 15px}  
  .landing h1{font-size: 25px;margin-top: 80px;}
}

body { /* Addresses a small issue in webkit: http://bit.ly/NEdoDq */
  -webkit-backface-visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
     -moz-animation-duration: 1s;
       -o-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
     -moz-animation-fill-mode: both;
       -o-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
  
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}