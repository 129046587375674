$color-green: #093;
$color-lightgreen: #82C300;
$color-darkgreen: #336633;

body {
  font-size: 14px;
}
.btn-primary, .bg-primary, .btn-primary.btn-circle > i {
  color: #fff;
  background-color: #00A4E1;
}
.btn-inverse, .bg-inverse, .btn-inverse.btn-circle > i {
  color: #fff;
  background-color: #aaa;
}
.btn-danger, .bg-danger, .btn-danger.btn-circle > i {
  color: #fff;
  background-color:  $color-green;
}
.main-visual .bg-inverse {
  background-color: rgba(35,52,69,0.96);
}
.navbar {
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.bg-inverse a {
  color: #fff;
}
a:focus, a:active {
  outline: none;
  border: none
}
.parsley-error-list li {
  color: red;
}
.navbar-brand {
  padding:16px 0 14px
}
.navbar-text small {
  font-size:14px
}
.navbar-text.pull-left.text-muted.hidden-xs {
  margin-top: 2.6%;
  color: #aaa
}
a, a img, input, button, select, textarea {
  -moz-transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
a:hover {
  color: $color-green;
}
.navbar-nav > li > a {
  font-size: 15px;
  padding: 8px 20px
}
.navbar-nav > li > a:hover {
  background: none;
  color: $color-green
}
.navbar-nav {
  margin-top: 1.6%
}

.navbar-nav > li.login {
  margin-left: 15px;
}
.navbar-nav > li.login a {
  padding: 6px 20px 8px;
  -moz-border-radius:5px;
  -webkit-border-radius:5px;
  border-radius:5px;
  color: $color-green;
  border: 1px solid  $color-green;
}
.navbar-nav > li.login a:hover {
  background:  $color-green;
  color: white;
}
.bg-inverse h4 {
  font-size: 13px;
}
.btn.btn-lg.btn-danger, .btn.btn-lg.btn-primary {
  background: none;
  border: 2px solid  $color-lightgreen;
  color:  $color-lightgreen;
  padding:11px 20px;
  -moz-box-shadow:none;
  -webkit-box-shadow:none;
  box-shadow:none;
  font-weight:normal;
}
.btn.btn-lg.btn-primary {
  border-color:#56D2FF;
  color: #56D2FF
}
.btn.btn-lg.btn-danger:hover, .btn.btn-lg.btn-primary:hover {
  background: $color-green;
  color: white;
  border-color: white;
}
.btn.btn-lg.btn-primary:hover {
  background:#1BA5DF
}
#pricing .btn-danger.btn-lg {
  padding: 8px 20px 10px;
  font-size:16px;
  border-width:1px;
}
#pricing .btn-danger.btn-lg:hover {
  border-color: $color-green;
}
.btn.btn-lg.btn-danger i, .btn.btn-lg.btn-primary i {
  font-size: 26px;
  line-height: 28px;
  margin-top: -3px;
  vertical-align: middle;
  display:inline-block;
}
.main-visual h1 {
  color: white;
}
.text-center.m-large > h2 {
  text-transform: uppercase;
  font-weight:500;
  letter-spacing:9px;
}
#features .text-left > small {
  color:#97E1FC;
  font-size: 12px;
  font-style:italic;
  display:block;
  margin-top:4px;
}
.bg-inverse {
  color:#ddd
}
.bg-inverse a {
  color: white;
}
.bg-inverse a:hover {
  text-shadow: 0 0 6px white;
}
.features {
  padding-bottom: 20px
}
.features .col-lg-4 > div {
  padding-left: 85px;
  position:relative
}
.features .col-lg-4 > div i {
  position: absolute;
  font-size: 59px;
  color:#97E1FC;
  top: 0;
  left: 0;
}

.features .col-lg-6 > div {
  padding-left: 85px;
  position:relative
}
.features .col-lg-6 > div i {
  position: absolute;
  font-size: 59px;
  top: 0;
  left: 0;
}

.admin .navbar-nav > li > a {
  font-size: 14px
}
.flat .plan li > strong {
  font-size: 15px;
}
.features hr {
  border-color:#97E1FC
}
.landing .gallery .thumbnail {
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
  border-radius:6px;
}
a.thumbnail:hover, a.thumbnail:focus {
  border-color:  $color-green;
  -moz-box-shadow: 0 0 8px  $color-green;
  -webkit-box-shadow: 0 0 8px  $color-green;
  box-shadow: 0 0 8px  $color-green;
}
.flat .plan {
  border: 1px solid #ccc;
}
.flat .plan li {
  color: #aaa;
}
.flat .plan li.plan-name {
  margin: -1px -1px 0 -1px;
}
#pricing .row.m-large.flat {
  margin-top: 35px;
}
.flat .plan {
  padding-bottom: 0;
}
.flat .plan li.plan-action {
  border-top:1px solid #F5F5F5;
  margin-top: 0;
  padding-top:10px;
}
#pricing hr {
  border-color:#ddd
}
.main-visual {
  /*background:url("../images/img-13.jpg") no-repeat scroll center center / cover #233445;*/
}
.center {
  text-align: center
}
.center .navbar-brand {
  border-right: none;
  float:none;
  display:inline-block
}

#header {position: relative}

.auth .help {
  width: 35px;
  height: 35px;
  line-height:20px;
  -moz-border-radius:100px;
  -webkit-border-radius:100px;
  border-radius:100px;
    border: 1px solid  $color-green;
  color: $color-green;
  font-size: 21px;
  position:absolute;
  right: 20px;
  top: 20px;
  margin-top:0;
}
.auth .help:hover {
  background:  $color-green;
  color: white;
}
i.text-default {
  color: inherit
}
.pane-footer-byline {
  display: none !important;
}
.panel-heading.text-center {
  font-size: 20px;
  color: #3A5A7A;
}
.panel-heading {
  background: #E7F7FF;
  border-bottom: 1px solid #ccc;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  padding:6px 15px;
  font-weight:600;
  font-size:16px;
  color:#233445;
}
.panel {
  -moz-box-shadow:0 2px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow:0 2px 6pxx rgba(0, 0, 0, 0.1);
  box-shadow:0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  -moz-border-radius:5px;
  -webkit-border-radius:5px;
  border-radius:5px;
}
input[type="text"], input[type="password"], input[type="email"], textarea, iframe {
  -moz-box-shadow:0 0 0 rgba(0,0,0,0), 0 2px 4px rgba(0,0,0,0.1) inset;
  -webkit-box-shadow:0 0 0 rgba(0,0,0,0), 0 2px 4px rgba(0,0,0,0.1) inset;
  box-shadow:0 0 0 rgba(0,0,0,0), 0 2px 4px rgba(0,0,0,0.1) inset;
}
iframe {
  border: 1px solid #ccc;
  -moz-border-radius:5px;
  -webkit-border-radius:5px;
  border-radius:5px;
}
input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, textarea:focus {
  border-color:  $color-green;
  -moz-box-shadow:0 0 8px  $color-green, 0 0 0 white inset;
  -webkit-box-shadow:0 0 8px  $color-green, 0 0 0 white inset;
  box-shadow:0 0 8px  $color-green, 0 0 0 white inset;
}
.auth .form-control {
  height: auto;
  line-height:inherit;
  padding:9px 12px;
}
.line-dashed {
  border-style: dotted
}
.btn-gray {
  background: #efefef
}
.btn-info {
  background: #1BA5DF;
  padding:8px 18px;
  font-size: 15px;
}
.btn-info:hover {
  background:  $color-green;
}
section.main > div > h4 > i {
  font-size: 24px;
  vertical-align:middle;
  color:#A8D7EA;
  margin-right:7px
}
.admin .nav > li > a:hover, .nav > li > a:focus {
  background: none;
}
.admin .nav > li > a:hover {
  color: white
}
.admin .nav > li > a.dropdown-toggle:hover {
  color:  $color-green
}
h4 {
  font-size: 22px;
}
#nav.nav-vertical {
  top: 75px;
  borer-right:none;
}
#nav.nav-vertical li.active a {
  background: #1CABE7;
  border: none;
  color: white;
}
.nav-vertical > ul > li > a {
  font-size: 14px;
}
.nav.navbar-nav.navbar-avatar.pull-right {
  margin-top:15px;
  margin-right:15px;
}
#header .dropdown-menu {
  top: 65px;
  padding:0;
  overflow:hidden;
  border: 1px solid #ccc
}
.navbar .nav.navbar-nav.navbar-avatar.pull-right li.dropdown.open > .dropdown-toggle {
  background: white;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dropdown-menu > li > a {
  padding: 8px 15px;
}
.dropdown-menu > li > a:hover {
  background: $color-green
}
.dropdown-menu .divider {
  margin: 0;
}
.dropdown.open a {
  color: $color-green
}
.dropdown.open .caret {
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent  $color-green transparent;
}
.dropdown.open .dropdown-menu a {
  color:#3A5A7A
}
.dropdown.open .dropdown-menu a:hover {
  color: white
}
.dashboard .navbar-brand:hover {
  background: #EFFAFF
}
h4.text-success {
  margin: 0
}
#customer_count {
  color: #777777;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: -3px;
}
.label.label-large.bg-default {
  background: rgba(0,0,0,0.2);
  font-size: 12px;
  margin-right:8px;
  -webkit-border-radius:3px;
  -moz-border-radius:3px;
  border-radius:3px;
  height: 25px;
  line-height:25px;
  vertical-align:middle;
  padding:0;
  display:inline-block;
  padding:0 8px;
}
.media-body.panel.text-small {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color:#D1D4D7
}
.media-body.panel.text-small i {
  font-size: 20px;
  vertical-align:middle;
}
.arrow-left:before {
  -moz-border-right-colors: inherit;
}
.scrollbar .arrow-left:before {
  left: 73px;
}
.comment-item:after {
  border-right-color: #fff;
  left: 73px
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.btn-circle:hover > i, .btn.btn-circle:hover > i {
  background:  $color-green
}
.btn-circle.btn-xs > i {
  font-size: 30px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  width: 50px;
}
.alert {
  padding: 7px 15px
}
.alert i {
  font-size: 24px;
  vertical-align:middle;
  margin-right:10px;
}
.auth .btn-white {
  background:#f7f8f9
}
.auth .btn-white:hover {
  background:white
}
.btn-white:active, .btn-white:focus {
  border: 1px solid #C3C6C9
}
.comment-item.media.arrow.arrow-left a {
  margin-right: 36px;
}
#footer .btn-circle.btn-xs > i {
  margin-top: 10px;
}
#footer .btn.btn-circle:hover {
  color: $color-green
}
.media .pull-left.thumb-small i.icon-envelope {
  color:#A5B6C7;
  font-size: 18px;
}
.panel-body.scrollbar.scroll-y.m-b {
  margin-bottom: 0
}
.timeline-caption .bg-primary h5 span {
  color: white;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger.btn-circle:hover > i {
  background:#FFAF3F
}
#emailpreview .btn-group {
  margin-left: 10px;
}
.timeline-item .timeline-caption .arrow-right:before {
  -moz-border-left-colors: inherit
}
.timeline-caption .panel.arrow.arrow-right.bg.bg-primary {
  background: $color-green;
}
.timeline-caption .panel.arrow.arrow-right.bg.bg-primary:after {
  border-left-color: $color-green;
}
.portlet-item .panel-heading .active.dropdown-toggle {
  display: inline-block;
  margin-top:4px;
}
.panel-heading .active.dropdown-toggle i {
  font-size: 20px;
}
.portlet-item .nav {
  margin:0;
}
.portlet-item .nav a {
  padding: 3px 5px;
}
.portlet-item .nav .open > a:focus {
  background: none
}
#emailpreview .bg-primary {
  background: #8E9EAE
}
.bg-warning {
  background: #FF5F5F
}
.input-sm {
  padding: 6px 10px;
  border-right: none;
}
.sidebar.bg.text-small .btn > i {
  font-size: 16px;
  line-height:normal
}
.badge {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.col-lg-5.alert.alert-info.alert-block {
  font-size: 85%;
  margin-left:16px;
}
.sidebar-small {
  width: 161px;
}
#cancel_stripe {
  background: none;
  -moz-box-shadow:none;
  -webkit-box-shadow:none;
  box-shadow:none;
  color:#FF5F5F
}
#cancel_stripe:hover {
  color:#0B76B5
}
.text-success {
  color:#29537C
}
.subscriptions .h3 {
  font-weight: 300
}
.subscriptions h5 {
  text-transform: uppercase;
  font-weight:bold;
  color: #61C5ED;
}
.subscriptions .panel-body small {
  font-weight: bold;
  background: #ccc;
  padding: 6px 14px;
  display: inline-block;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  border-radius:3px;
  color:white;
}
.subscriptions form {
  margin-bottom: 0
}
@media only screen and (max-width: 768px) {
.nav-toggle {font-size: 24px;}

#nav.nav-vertical {top: 0;}
.slide-nav #nav {width: 33%}
.slide-nav > #header, .slide-nav > #content {
    overflow: hidden;
    transform: translate3d(33%, 0px, 0px);
}

}
@media only screen and (max-width: 480px) {
  .slide-nav #nav {width: 75%}
  .slide-nav > #header, .slide-nav > #content {
    overflow: hidden;
    transform: translate3d(75%, 0px, 0px);
}
}

.subscriptions .panel-body {min-height: 225px}


/* ================================================
// Style
================================================ */

.navbar-brand .logo {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  display: block;
  height: 30px;
  background-image: url("../img/logo.png");
  background-repeat: no-repeat;
  background-position: center center;
}

#content{
  margin-top: 60px;
}